import { Inject, Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { EkonAuthService, OidcUser } from '@ekon-client/auth';
import { LandingPage, LandingPageServiceInterface, PermissionGroup, SubscriptionPlan } from '@ekon-client/dkm-api';
import { LANDING_PAGE_ACTIONS, LANDING_PAGE_EVENTS, LandingPageEventsServiceInterface } from '@ekon-client/dkm-events';
import { combineLatest, merge, Observable, of, Subject } from 'rxjs';
import { filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';

export type EkonPermissionActionType = 'read' | 'update' | 'create' | 'remove' | 'config' | 'invite';

export interface PermissionsGranted {
  read: boolean,
  update: boolean,
  create: boolean,
  remove: boolean,
  invite: boolean,
  config: boolean
}

export interface WithPermissions<T> {
  permissions: PermissionsGranted;
  data: T
}

@Injectable({
  providedIn: 'root'
})
export class PermissionsService implements OnDestroy {
  private readonly _destroy$ = new Subject<void>();

  subscriptionPlans$: Observable<SubscriptionPlan[]>;
  subscriptionPlan$: Observable<SubscriptionPlan>;
  permissions$: Observable<PermissionGroup>;

  constructor(
    private router: Router,
    private authService: EkonAuthService,
    @Inject(LANDING_PAGE_ACTIONS) private lpActions: LandingPageServiceInterface,
    @Inject(LANDING_PAGE_EVENTS) private lpEvents: LandingPageEventsServiceInterface
  ) {
    this.subscriptionPlans$ = merge(
      of(true),
      this.lpEvents.landingPageUpdated
    ).pipe(
      switchMap(() => lpActions.findDefaultLandingPage()),
      map((lp: LandingPage) => lp?.subscriptionPlans),
      tap((subscriptionPlans) => console.log('PermissionsService subscriptionPlans updated: ', JSON.stringify(subscriptionPlans))),
      takeUntil(this._destroy$)
    );

    this.subscriptionPlan$ = combineLatest([this.subscriptionPlans$, this.authService.isLoggedIn$, this.authService.user$])
      .pipe(
        filter(([, isLoggedIn, user]: [SubscriptionPlan[], boolean, OidcUser]) => isLoggedIn === true && user !== null),
        map(([sp, , user]: [SubscriptionPlan[], boolean, OidcUser]) => {
          console.log('PermissionsService current user: ', JSON.stringify(user));
          const currentPlan = sp?.find(
            (plan: SubscriptionPlan) => plan.subscriptionPlanType === user.role
          );
          return currentPlan;
        }),
        tap((selectedPlan) => console.log('PermissionsService sellected subscriptionPlan: ', JSON.stringify(selectedPlan))),
        takeUntil(this._destroy$)
      );

    this.permissions$ = this.subscriptionPlan$.pipe(
      map((plan: SubscriptionPlan) => {
        const permissions = plan ? plan.featuresPermission : null;
        return permissions;
      }),
      tap((permissions) => console.log('PermissionsService all permissions for user: ', JSON.stringify(permissions))),
      takeUntil(this._destroy$)
    );

  }
  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  getSharedUserPermissions(role: string): Observable<PermissionGroup> {
    return this.subscriptionPlans$.pipe(
      map((sp: SubscriptionPlan[]) => sp?.find(
        (plan: SubscriptionPlan) => plan.subscriptionPlanType === role
      )),
      map((plan: SubscriptionPlan) => {
        return plan ? plan.featuresPermission : null;
      }),
      tap((val) => console.log('PermissionsService getSharedUserPermissions plan by role: ', val, role)),
      takeUntil(this._destroy$)
    )
  }

  hasPermission(featureKey: string, action: EkonPermissionActionType, p?: Observable<PermissionGroup>): Observable<boolean> {
    console.log('PermissionsService hasPermission requested for featureKey and action: ', featureKey, action);
    return (p || this.permissions$).pipe(
      map((permissions: PermissionGroup) => {
        if (!featureKey && !action) {
          console.log('PermissionsService hasPermission no permission verification requested. Allow any user.');
          return true;
        }

        if (!featureKey || !action) {
          console.log('PermissionsService hasPermission missing featureKey or action. Deny user.', featureKey, action);
          return false;
        }

        featureKey = featureKey.toLowerCase();

        if (!permissions) {
          console.log('PermissionsService hasPermission no permissions found for desired feature. Deny user access.', featureKey, JSON.stringify(permissions));
          return false;
        }

        const permission = permissions[action].permission;
        const exceptions = permissions[action].exceptions;
        console.log('PermissionsService hasPermission found permission for a feature.', JSON.stringify(permissions), JSON.stringify(exceptions));
        return (permission && (!exceptions || !exceptions.some(excp => excp.toLowerCase() === featureKey)))
          || (!permission && exceptions && exceptions.some(excp => excp.toLowerCase() === featureKey));
      }),
      tap((val) => console.log('PermissionsService hasPermission is allowed: ', val)),
      takeUntil(this._destroy$)
    );
  }

  hasAnyPermission(): Observable<boolean> {
    return this.permissions$.pipe(
      map((permissions: PermissionGroup) => Boolean(permissions)),
      tap((val) => console.log('PermissionsService hasAnyPermission', JSON.stringify(val))),
      takeUntil(this._destroy$)
    );
  }

  getPermissionGroup(featureKey: string): Observable<PermissionsGranted> {
    return combineLatest([
      this.hasPermission(featureKey, 'read'),
      this.hasPermission(featureKey, 'update'),
      this.hasPermission(featureKey, 'create'),
      this.hasPermission(featureKey, 'remove'),
      this.hasPermission(featureKey, 'invite'),
      this.hasPermission(featureKey, 'config')
    ]).pipe(
      map(
        ([read, update, create, remove, invite, config]: boolean[]) =>
          ({ read, update, create, remove, invite, config })
      ),
      tap((val) => console.log('PermissionsService getPermissionGroup', JSON.stringify(val))),
      takeUntil(this._destroy$)
    );
  }

  withPermissions<T>(data: T, featureKey: string): Observable<WithPermissions<T>> {
    return this.getPermissionGroup(featureKey).pipe(
      map((permissions: PermissionsGranted) => ({
        permissions: permissions,
        data
      })),
      tap((val) => console.log('PermissionsService withPermissions: ', JSON.stringify(val))),
      takeUntil(this._destroy$)
    );
  }
}
